
































import {
  computed, ref, useRoute, defineComponent, useFetch, useContext
} from '@nuxtjs/composition-api';
import { SfHeading, SfCallToAction } from '@storefront-ui/vue';
import { addBasePath } from '~/helpers/addBasePath';
import HeadlessTwoColBanner from '~/components/Headless/TwoColBanner.vue';
import PrimaryButton  from '~/components/Headless/PrimaryButton.vue';
import { setSirvImage } from '~/helpers/headless/helpers';

export default defineComponent({
  components: {
    SfHeading,
    SfCallToAction,
    PrimaryButton,
    HeadlessTwoColBanner
  },
  setup(props, context) {
    context.emit('changeStep', 4);
    const route = useRoute();
    const orderNumber = computed(() => route.value.query.order ?? '');

    // Fetch Strapi checkout charactistics data
    const { app, $strapi } = useContext();
    const qs = require('qs');
    const query = qs.stringify({
      populate: [
        'content',
        'content.Images.image.media'
      ],
    }, {
      encodeValuesOnly: true, // prettify URL
    });

    // Get Strapi Data
    const strapiData = ref<Object>({});
    useFetch(async () => {
      strapiData.value = await $strapi.find('thank-you-page?' + query);
    });

    return {
      addBasePath,
      strapiData,
      orderNumber,
      setSirvImage
    };
  },
});
